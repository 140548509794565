import React, {
  useState,
} from 'react'
import update from 'immutability-helper'
import { throttle } from 'lodash'
import {
  Card,
  TextField,
  Popover,
  FormLayout,
  ColorPicker,
  RangeSlider,
  ButtonGroup,
  Button,
  LegacyStack,
  RadioButton
} from "@shopify/polaris"
function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = n => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0');   // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}
function ButtonsTab(props) {
  const { state, setState } = props;
  const [ type, setType ] = useState('product')
  const [ctaBackgroundColorPopoverVisible, setCtaBackgroundColorPopoverVisible] = useState(false)
  const [ctaBackgroundColorHsl, setCtaBackgroundColorHsl] = useState({
    hue: 131,
    saturation: 86,
    brightness: 34,
    alpha: 1,
  })
  const [ctaBorderColorPopoverVisible, setCtaBorderColorPopoverVisible] = useState(false)
  const [ctaBorderColorHsl, setCtaBorderColorHsl] = useState({
    hue: 131,
    saturation: 86,
    brightness: 34,
    alpha: 1,
  })
  const [ctaButtonTextColorPopoverVisible, setCtaButtonTextColorPopoverVisible] = useState(false)
  const [ctaButtonTextColorHsl, setCtaButtonTextColorHsl] = useState({
    hue: 0,
    saturation: 0,
    brightness: 100,
    alpha: 1,
  })
  const [checkoutButtonTextColorPopoverVisible, setCheckoutButtonTextColorPopoverVisible] = useState(false)
  const [checkoutButtonTextColorHsl, setCheckoutButtonTextColorHsl] = useState({
    hue: 131,
    saturation: 86,
    brightness: 34,
    alpha: 1,
  })

  const ctaBackgroundColorActivatorButton = (
    <TextField
      label="Button Color"
      onFocus={() => setCtaBackgroundColorPopoverVisible(true)}
      value={state.ctaBackgroundColor}
      onChange={(val) => setState(update(state, { ctaBackgroundColor: { $set: val }})) }
      connectedLeft={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            marginRight: 8,
            backgroundColor: state.ctaBackgroundColor,
            border: state.ctaBackgroundColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setCtaBackgroundColorPopoverVisible(true)}
        />
      }
    />
  )

  const onCtaBackgroundColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaBackgroundColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaBackgroundColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaBackgroundColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaBackgroundColorHsl(color)
    setState(update(state, { ctaBackgroundColor: { $set: hex }}))
  }
  const onCtaBackgroundColorChangeThrottled = throttle(onCtaBackgroundColorChange, 250)

  const ctaBorderColorActivatorButton = (
    <TextField
      label="Button border color"
      onFocus={() => setCtaBorderColorPopoverVisible(true)}
      value={state.ctaBorderColor}
      onChange={(val) => setState(update(state, { ctaBorderColor: { $set: val }})) }
      connectedLeft={
        <div
          style={{
            borderRadius: 5,
            cursor: 'pointer',
            height: 36,
            width: 40,
            marginRight: 8,
            backgroundColor: state.ctaBorderColor,
            border: state.ctaBorderColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setCtaBorderColorPopoverVisible(true)}
        />
      }
    />
  )

  const onCtaBorderColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaBorderColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaBorderColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaBorderColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    setCtaBorderColorHsl(color)
    setState(update(state, { ctaBorderColor: { $set: hex }}))
  }
  const onCtaBorderColorChangeThrottled = throttle(onCtaBorderColorChange, 250)

  const ctaButtonTextColorActivatorButton = (
    <TextField 
      label="Button Text Color" 
      onFocus={() => setCtaButtonTextColorPopoverVisible(true)} 
      value={state.ctaButtonTextColor}
      onChange={(val) => setState(update(state, { ctaButtonTextColor: { $set: val }})) }
      connectedLeft={
        <div 
          style={{ 
            borderRadius: 5,
            cursor: 'pointer',
            height: 36, 
            width: 40,
            marginRight: 8,
            backgroundColor: state.ctaButtonTextColor,
            border: state.ctaButtonTextColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setCtaButtonTextColorPopoverVisible(true)} 
        />
      }
    />
  )
  const onCtaButtonTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || ctaButtonTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || ctaButtonTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || ctaButtonTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCtaButtonTextColorHsl(color)
    setState(update(state, { ctaButtonTextColor: { $set: hex }}))
  }
  const onCtaButtonTextColorChangeThrottled = throttle(onCtaButtonTextColorChange, 250)


  const checkoutButtonTextColorActivatorButton = (
    <TextField 
      label="Button text color" 
      onFocus={() => setCheckoutButtonTextColorPopoverVisible(true)}
      value={state.checkoutTextColor}
      onChange={(val) => setState(update(state, { checkoutTextColor: { $set: val }})) } 
      connectedLeft={
        <div 
          style={{ 
            borderRadius: 5,
            cursor: 'pointer',
            height: 36, 
            width: 40,
            marginRight: 8,
            backgroundColor: state.checkoutTextColor,
            border: state.checkoutTextColor.toLowerCase() === '#ffffff' && '1px solid #000000'
          }}
          onClick={() => setCheckoutButtonTextColorPopoverVisible(true)} 
        />
      }
    />    
  )
  const onCheckoutButtonTextColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || checkoutButtonTextColorHsl.hue || 0,
      saturation: updatedColor.saturation || checkoutButtonTextColorHsl.saturation || 0,
      brightness: updatedColor.brightness || checkoutButtonTextColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100,
    )

    console.log('Color changed: ', color, hex)

    setCheckoutButtonTextColorHsl(color)
    setState(update(state, { checkoutTextColor: { $set: hex }}))
  }
  const onCheckoutButtonTextColorChangeThrottled = throttle(onCheckoutButtonTextColorChange, 250)

  const suffixStyles = {
    minWidth: '24px',
    textAlign: 'right',
  };
  
  return (
    <FormLayout>
      <FormLayout>
        <LegacyStack wrap={false} alignment="trailing">
          <LegacyStack.Item fill>
            <TextField
              label="Button text"
              placeholder="Add to cart"
              onChange={(val) => setState(update(state, { cta: { $set: val }})) }
              value={state.cta}
            />
          </LegacyStack.Item>
        </LegacyStack>
        <Popover
          active={ctaButtonTextColorPopoverVisible}
          activator={ctaButtonTextColorActivatorButton}
          onClose={() => setCtaButtonTextColorPopoverVisible(false)}
          sectioned
        > 
          <ColorPicker
            color={ctaButtonTextColorHsl}
            onChange={onCtaButtonTextColorChangeThrottled}
          />
        </Popover>
        <Popover
          active={ctaBackgroundColorPopoverVisible}
          activator={ctaBackgroundColorActivatorButton}
          onClose={() => setCtaBackgroundColorPopoverVisible(false)}
        >
          <ColorPicker
            color={ctaBackgroundColorHsl}
            onChange={onCtaBackgroundColorChangeThrottled}
          />
        </Popover>
        <Popover
          active={ctaBorderColorPopoverVisible}
          activator={ctaBorderColorActivatorButton}
          onClose={() => setCtaBorderColorPopoverVisible(false)}
        >
          <ColorPicker
            color={ctaBorderColorHsl}
            onChange={onCtaBorderColorChangeThrottled}
          />
        </Popover>
        <RangeSlider
          label="Button border radius"
          prefix={<p>px</p>}
          suffix={<p style={suffixStyles}>{state.buttonBorderRadius}</p>}
          value={state.buttonBorderRadius}
          onChange={(val) => setState(update(state, { buttonBorderRadius: { $set: val }})) }
          output
        />
        <RangeSlider
          label="Button border thickness"
          prefix={<p>px</p>}
          suffix={<p style={suffixStyles}>{state.buttonBorderThickness}</p>}
          value={state.buttonBorderThickness}
          onChange={(val) => setState(update(state, { buttonBorderThickness: { $set: val }})) }
          output
        />
        <RangeSlider
          label="Button height"
          prefix={<p>px</p>}
          suffix={<p style={suffixStyles}>{state.buttonHeight}</p>}
          value={state.buttonHeight}
          onChange={(val) => setState(update(state, { buttonHeight: { $set: val }}))}
          output
        />
      </FormLayout>
    </FormLayout>
  )
}

export default ButtonsTab;